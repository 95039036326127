import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class SuscriptionProvider {
  constructor(public http: HttpClient) { }

  public create(form: any): Observable<any> {
    return this.http.post(environment.ENDPOINT + environment.SERVICES.SUSCRIPTION, form);
  }

  public update(form: any): Observable<any> {
    return this.http.put(`${environment.ENDPOINT}${environment.SERVICES.SUSCRIPTION}/${form.rut}`, form);
  }

  public getAll(): Observable<any> {
    return this.http.get(environment.ENDPOINT + environment.SERVICES.SUSCRIPTION);
  }
}
